import React from "react"
import Layout from './../components/shared/Layout';
import SectionArticles from './../components/section/SectionArticles';

const GuidesPage = () => (
  <Layout title="Guides">
      <SectionArticles section="Guides" description="Cafe Living Guides" title="Guides"/>
  </Layout>
)

export default GuidesPage;
